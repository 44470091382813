<template>
    <div class="preview_area show_sidebar" :class="{ active : modelValue }" v-if="modelValue" @click.self="closeModal">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'brand' ? 'active' : ''" @click="tab = 'brand'">Brand Bar</li>
                <li :class="tab === 'bio' ? 'active' : ''" @click="tab = 'bio'">Bio</li>
                <li :class="tab === 'line' ? 'active' : ''" @click="tab = 'line'">Line Bar</li>
                <li :class="tab === 'social' ? 'active' : ''" @click="tab = 'social'">Social</li>
            </ul>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionBar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <Form @submit="handleSubmit" :validation-schema="schema" v-slot="{ errors }" ref="add-signature-form" class="forms-setting-form">
                    <div class="content_area">
                        <div class="content_wpr">
                            <Field autocomplete="off" name="signature_name" v-model="form.signature_name" type="hidden"/>
                            <div class="section_content w-100" v-if="tab === 'brand'">
                                <div class="section_header">
                                    <h2>Signature Name <small>(Internal use only)</small></h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mt-2">
                                        <div class="group_item">
                                            <div class="field_wpr" :class="{ 'has-error': errors.signature_name }">
                                                <Field autocomplete="off" name="signature_name" v-model="form.signature_name" type="text" :placeholder="placeholder.signature_name" />
                                            </div>
                                            <ErrorMessage name="signature_name" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'brand'">
                                <div class="section_header">
                                    <h2>Brand Bar</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Full Name</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="full_name" v-model="form.full_name" :placeholder="placeholder.full_name" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Credentials</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="credentials" v-model="form.credentials" :placeholder="placeholder.credentials" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Company Name</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="company_name" v-model="form.company_name" :placeholder="placeholder.company_name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Top Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="top_margin" min="0" max="100" placeholder="10" v-model="form.top_margin" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Bottom Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="bottom_margin" min="0" max="100" placeholder="10" v-model="form.bottom_margin" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Left Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="left_margin" min="0" max="100" placeholder="10" v-model="form.left_margin" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Right Margin</label>
                                                <div class="field_wpr m-0">
                                                    <input type="number" name="right_margin" min="0" max="100" placeholder="10" v-model="form.right_margin" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Top Padding</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="top_padding" v-model="form.top_padding" placeholder="10" min="10" max="300" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Bottom Padding</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="bottom_padding" v-model="form.bottom_padding" placeholder="10" min="10" max="300" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Left Padding</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="left_padding" min="0" max="100" placeholder="30" v-model="form.left_padding" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Right Padding</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="right_padding" min="0" max="100" placeholder="30" v-model="form.right_padding" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Corner Radius</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="border_radius" v-model="form.border_radius" placeholder="10" min="1" max="50" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Bar Color <small class="pointer" @click="resetToDefaultBarColor()">Reset to default color</small></label>
                                                <color-picker v-model="form.bar_color" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Bar Text Color</label>
                                                <color-picker v-model="form.bar_text_color" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'bio'">
                                <div class="section_header">
                                    <h2>Bio</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Slogan</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="text" name="slogan" v-model="form.slogan" :placeholder="placeholder.slogan" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="upload_image mt-4 mb-4">
                                            <label for="logo">
                                                <image-library v-model="form.author_image" image-type="coach-logo" :is-avatar="true" />
                                            </label>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showphone" class="switch_option capsule_btn border-0 m-0">
                                                <h5>Phone Number</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_phone" id="showphone" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.has_phone">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix">
                                                            <input type="text" v-model="form.phone" :placeholder="placeholder.phone" ref="phone" />
                                                            <span class="prefix"><i class="fas fa-phone-alt"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showemail" class="switch_option capsule_btn border-0 m-0">
                                                <h5>E-mail</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_email" id="showemail" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.has_email">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.email }">
                                                            <Field autocomplete="off" type="email" name="email" v-model="form.email" :placeholder="placeholder.email" />
                                                            <span class="prefix"><i class="fas fa-envelope"></i></span>
                                                        </div>
                                                        <ErrorMessage name="email" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showaddress" class="switch_option capsule_btn border-0 m-0">
                                                <h5>Address</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_address" id="showaddress" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.has_address">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr" :class="{ 'has-error': errors.address }">
                                                            <Field autocomplete="off" type="text" name="address" v-model="form.address" :placeholder="placeholder.address" />
                                                        </div>
                                                        <ErrorMessage name="address" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showwebsite" class="switch_option capsule_btn border-0 m-0">
                                                <h5>Website</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.has_website" id="showwebsite" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.has_website">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.website }">
                                                            <Field autocomplete="off" type="text" name="website" v-model="form.website" :placeholder="placeholder.website" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="website" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Abbreviation Text Color <small class="pointer" @click="resetToDefaultIconColor()">Reset to default color</small></label>
                                                <color-picker v-model="form.icon_color" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Link Color</label>
                                                <color-picker v-model="form.link_color" />
                                            </div>
                                        </div>
                                        <label for="abbreviate" class="switch_option capsule_btn p-0 border-0 mt-5 mb-2">
                                            <h5 class="large">Abbreviate</h5>
                                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_abbreviate" name="is_abbreviate" id="abbreviate" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'line'">
                                <div class="section_header">
                                    <h2>Line Bar</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Thickness</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" type="number" name="linebar_height" min="0" max="100" v-model="form.linebar_height" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Width</label>
                                                <div class="field_wpr has_suffix" :class="{ 'has-error': errors.linebar_width }">
                                                    <Field autocomplete="off" type="number" name="linebar_width" min="0" max="100" v-model="form.linebar_width" />
                                                    <span class="suffix">%</span>
                                                </div>
                                                <ErrorMessage name="linebar_width" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Top Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="linebar_top_margin" min="0" max="100" placeholder="10" v-model="form.linebar_mt" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Bottom Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="linebar_bottom_margin" min="0" max="100" placeholder="10" v-model="form.linebar_mb" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Line Bar Color <small class="pointer" @click="resetToDefaultLineBarColor()">Reset to default color</small></label>
                                                <color-picker v-model="form.linebar_color" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'social'">
                                <div class="section_header">
                                    <h2>Social</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Top Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="social_top_margin" min="0" max="100" placeholder="10" v-model="form.social_mt" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Bottom Margin</label>
                                                <div class="field_wpr m-0">
                                                    <Field autocomplete="off" type="number" name="social_bottom_margin" min="0" max="100" placeholder="10" v-model="form.social_mb" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showfacebook" class="switch_option capsule_btn border-0 m-0">
                                                <h5>Show Facebook</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_facebook" name="is_facebook" id="showfacebook" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.is_facebook" class="mb-2">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.facebook_url }">
                                                            <Field autocomplete="off" type="text" name="facebook_url" v-model="form.facebook_url" placeholder="https://facebook.com/superfitcoaching" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="facebook_url" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="fbicon" class="switch_option capsule_btn">
                                                    <h5>Use Default Facebook Icon</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_facebook_icon" name="is_facebook_icon" id="fbicon" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="!form.is_facebook_icon" class="upload_image social_icon">
                                                    <image-library v-model="form.facebook_icon" image-type="favicon" :is-avatar="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showinstagram" class="switch_option capsule_btn border-0 m-0">
                                                <h5>Show Instagram</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_ig" id="showinstagram" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.is_ig" class="mb-2">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.ig_url }">
                                                            <Field autocomplete="off" type="text" name="ig_url" v-model="form.ig_url" placeholder="https://instagram.com/superfitcoaching" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="ig_url" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="instaicon" class="switch_option capsule_btn">
                                                    <h5>Use Default Instagram Icon</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_ig_icon" id="instaicon" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="!form.is_ig_icon" class="upload_image">
                                                    <image-library v-model="form.ig_icon" image-type="favicon" :is-avatar="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showtwitter" class="switch_option capsule_btn border-0 m-0">
                                                <h5>Show Twitter</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_twitter" id="showtwitter" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.is_twitter">
                                                <div class="form_grp pt-2">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.twitter_url }">
                                                            <Field autocomplete="off" type="text" name="twitter_url" v-model="form.twitter_url" placeholder="https://twitter.com/superfitcoaching" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="twitter_url" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="twittericon" class="switch_option capsule_btn">
                                                    <h5>Use Default Twitter Icon</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_twitter_icon" id="twittericon" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="!form.is_twitter_icon" class="upload_image">
                                                    <image-library v-model="form.twitter_icon" image-type="favicon" :is-avatar="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showlinkedin" class="switch_option capsule_btn border-0">
                                                <h5>Show Linkedin</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_linkedin" id="showlinkedin" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.is_linkedin">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix">
                                                            <Field autocomplete="off" type="text" name="linkedin_url" v-model="form.linkedin_url" placeholder="https://linkedin.com/superfitcoaching" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="linkedin_url" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="linkedinicon" class="switch_option capsule_btn">
                                                    <h5>Use Default Linkedin Icon</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_linkedin_icon" id="linkedinicon" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="!form.is_linkedin_icon" class="upload_image">
                                                    <image-library v-model="form.linkedin_icon" image-type="favicon" :is-avatar="false" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit_section mb-4">
                                            <label for="showYoutube" class="switch_option capsule_btn border-0">
                                                <h5>Show Youtube</h5>
                                                <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_yt" id="showYoutube" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.is_yt">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <div class="field_wpr has_prefix">
                                                            <Field autocomplete="off" type="text" name="youtube_url" v-model="form.yt_url" placeholder="https://youtube.com/superfitcoaching" />
                                                            <span class="prefix">URL</span>
                                                        </div>
                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                        <ErrorMessage name="youtube_url" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="youtubeicon" class="switch_option capsule_btn">
                                                    <h5>Use Default youtube Icon</h5>
                                                    <input type="checkbox" :true-value="1" :false-value="0" v-model="form.is_yt_icon" id="youtubeicon" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="!form.is_yt_icon" class="upload_image">
                                                    <image-library v-model="form.yt_icon" image-type="favicon" :is-avatar="false" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'social'">
                                <div class="color_container">
                                    <div :class="{'border-bottom': form.use_default}">
                                        <label for="usedefault" class="switch_option capsule_btn p-0 border-0 mt-2 mb-2">
                                            <h5 class="large">Use As Default Signature</h5>
                                            <input type="checkbox" :true-value="1" :false-value="0" v-model="form.use_default" id="usedefault" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <label for="defaultsig" v-if="form.use_default" class="switch_option capsule_btn">
                                        <h5>Show default signature on all emails</h5>
                                        <input type="checkbox" :true-value="1" :false-value="0" v-model="form.apply_to_all" id="defaultsig" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    <div class="action_wpr">
                        <button :disabled="signatureLoader" type="button" class="btn cancel_btn" @click="closeModal">Exit</button>
                        <button :disabled="signatureLoader" type="button" class="btn cancel_btn" @click="handleDeleteSignature" v-if="editSignature.id">Delete</button>
                        <button :disabled="signatureLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="signatureLoader"></i>{{ signatureLoader ? ' Saving' : 'Save'}}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }">
                <div class="header">
                    <div class="section_header m-0">
                        <h2>Preview</h2>
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <label for="preview_page_setting" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_page_setting" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="prev_content">
                    <div class="email-content">
                        <p>Hi {{ user.full_name }}! </p>
                        <p>Welcome to the signature feature. You can create stunning email signatures that look amazing by editing your information on the left. You will see your signature appear in real time. When you are happy with your creation just hit the save button! </p>
                    </div>
                    <div class="mail_card" :style="`margin: ${form.top_margin}px ${form.right_margin}px ${form.bottom_margin}px ${form.left_margin}px;`">
                        <div class="mail_brand" :style="`padding: ${form.top_padding}px ${form.right_padding}px ${form.bottom_padding}px ${form.left_padding}px; border-radius: ${form.border_radius}px; background-color: ${form.bar_color};`">
                            <div class="usr_details">
                                <h3 :style="`color: ${form.bar_text_color};`">{{ form.full_name ? form.full_name : placeholder.full_name }}
                                    <span :style="`color: ${form.bar_text_color};`"> {{ form.credentials ? ` - &nbsp${form.credentials}` : ` - &nbsp${placeholder.credentials}` }}</span>
                                </h3>
                                <h6 :style="`color: ${form.bar_text_color};`">{{ form.company_name ? form.company_name : placeholder.company_name }}</h6>
                            </div>
                        </div>
                        <div class="mail_bio">
                            <p class="px-0 slogan">{{ form.slogan  ? form.slogan : placeholder.slogan }}</p>
                            <div class="bio_details">
                                <img :src="form.author_image ? form.author_image : require('@/assets/images/avatar-mask.png')" alt="">
                                <ul class="bio">
                                    <li v-if="form.has_phone" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                        <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">P:
                                            <!-- <i class="fas fa-phone-alt"></i> -->
                                        </label>
                                        <label :style="`color: ${form.icon_color};`" v-else>Phone:</label>
                                        <a style="text-decoration: none;" :style="`color: ${form.link_color};`" :href="`tel:${form.phone}`">{{ form.phone ? form.phone : placeholder.phone }}</a>
                                    </li>
                                    <li v-if="form.has_email" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                        <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">E:
                                            <!-- <i class="fas fa-envelope"></i> -->
                                        </label>
                                        <label :style="`color: ${form.icon_color};`" v-else>Email:</label>
                                        <a style="text-decoration: none;" :style="`color: ${form.link_color};`" :href="`mailto:${form.email}`"> {{ form.email ? form.email : placeholder.email }}</a>
                                    </li>
                                    <li v-if="form.has_website" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                        <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">W:
                                            <!-- <i class="fas fa-globe-americas"></i> -->
                                        </label>
                                        <label :style="`color: ${form.icon_color};`" v-else>Website:</label>
                                        <a style="text-decoration: none;" :style="`color: ${form.link_color};`" :href="form.website"> {{ form.website ? form.website : placeholder.website }}</a>
                                    </li>
                                    <li v-if="form.has_address && form.address" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                        <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">A:
                                            <!-- <i class="fas fa-location-arrow"></i> -->
                                        </label>
                                        <label :style="`color: ${form.icon_color};`" v-else>Address:</label>
                                        {{ form.address ? form.address : placeholder.address }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="mail_bar">
                            <div class="bar" :style="`height: ${form.linebar_height}px; width: ${form.linebar_width}%; background-color: ${form.linebar_color}; margin-top: ${form.linebar_mt}px; margin-bottom: ${form.linebar_mb}px;`"></div>
                            <ul class="social-v1" :style="`margin-top:${form.social_mt}px; margin-bottom:${form.social_mb}px;`">
                                <li v-if="form.is_facebook">
                                    <a :href="form.facebook_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                        <img v-if="form.is_facebook_icon" :src="require('@/assets/images/facebook-v2.svg')">
                                        <img v-else :src="form.facebook_icon ? form.facebook_icon : require('@/assets/images/facebook-v2.svg')">
                                    </a>
                                </li>
                                <li v-if="form.is_ig">
                                    <a :href="form.ig_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                        <img v-if="form.is_ig_icon" :src="require('@/assets/images/instagram-v2.svg')">
                                        <img v-else :src="form.ig_icon ? form.ig_icon : require('@/assets/images/instagram-v2.svg')">
                                    </a>
                                </li>
                                <li v-if="form.is_twitter">
                                    <a :href="form.twitter_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                        <img v-if="form.is_twitter_icon" :src="require('@/assets/images/twitter-v2.svg')" alt="">
                                        <img v-else :src="form.twitter_icon ? form.twitter_icon : require('@/assets/images/twitter-v2.svg')" alt="">
                                    </a>
                                </li>
                                <li v-if="form.is_linkedin">
                                    <a :href="form.linkedin_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                        <img v-if="form.is_linkedin_icon" :src="require('@/assets/images/linkedin-v2.svg')" alt="">
                                        <img v-else :src="form.linkedin_icon ? form.linkedin_icon : require('@/assets/images/linkedin-v2.svg')" alt="">
                                    </a>
                                </li>
                                <li v-if="form.is_yt">
                                    <a :href="form.yt_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                        <img v-if="form.is_yt_icon" :src="require('@/assets/images/youtube-v2.svg')" alt="">
                                        <img v-else :src="form.yt_icon ? form.yt_icon : require('@/assets/images/youtube-v2.svg')" alt="">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="text-center">
                        <p>
                            <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><span :style="`color: ${form.icon_color};`">⚡</span> Powered by {{ whiteLabel.powered_by }}</a>
                                <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><span :style="`color: ${form.icon_color};`">⚡</span> Powered by {{ whiteLabel.powered_by }}</a>
                            </template>
                            <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><span :style="`color: ${form.icon_color};`">⚡</span> Powered by Thrive Coach</a>
                        </p>
                        <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                        <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                    </div>
                </div>
                <div v-if="showSignaturePreview" style="margin: 5px;">
                    <div class="form_grp">
                        <div class="group_item w-100">
                            <label class="input_label">Email Address</label>
                            <div class="field_wpr has_prefix" style="margin-top: 5px">
                                <Field autocomplete="off" name="email_address" type="email" v-model="previewForm.email_address" :placeholder="placeholder.email" />
                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="action_wpr mb-4 mt-4">
                        <button class="btn cancel_btn" :disabled="sendSignatureLoader" type="button" @click="showSignaturePreview = false">Cancel</button>
                        <button :disabled="sendSignatureLoader" type="button" class="btn save_btn" v-if="editSignature.id" @click="handleSignatureSend">
                            <i class="fa fa-spin fa-spinner" v-if="sendSignatureLoader"></i> {{ sendSignatureLoader ? 'Sending' : 'Send Preview' }}
                        </button>
                    </div>
                </div>
                <div class="action_wpr" v-if="showSignaturePreview">
                    <button class="btn save_btn" type="button" @click="showSignaturePreview = true">Show Send Preview</button>
                </div>
            </div>
            <full-preview v-model="fullPreview">
                <div class="dashboard">
                    <div class="msg_preview">
                        <p>
                            Hi {{ user.full_name }}!<br/><br/>
                            Welcome to the signature feature. You can create stunning email signatures that look amazing by editing your information on the left. You will see your signature appear in real time. When you are happy with your creation just hit the save button!
                        </p>
                        <div class="email-signature" :style="`margin: ${form.top_margin}px ${form.right_margin}px ${form.bottom_margin}px ${form.left_margin}px;`">
                            <div :style="`padding: ${form.top_padding}px ${form.right_padding}px ${form.bottom_padding}px ${form.left_padding}px; border-radius: ${form.border_radius}px; background-color: ${form.bar_color};`">
                                <div class="usr_details">
                                    <h3 :style="`color: ${form.bar_text_color};`">{{ form.full_name ? form.full_name : placeholder.full_name }}
                                        <span :style="`color: ${form.bar_text_color};`"> {{ form.credentials ? ` - &nbsp${form.credentials}` : ` - &nbsp${placeholder.credentials}` }}</span>
                                    </h3>
                                    <h6 :style="`color: ${form.bar_text_color};`">{{ form.company_name ? form.company_name : '-' }}</h6>
                                </div>
                            </div>
                            <div class="mail_bio">
                                <p class="px-0 slogan">{{ form.slogan ? form.slogan : placeholder.slogan }}</p>
                                <div class="bio_details">
                                    <img :src="form.author_image ? form.author_image : require('@/assets/images/avatar-mask.png')" alt="">
                                    <ul class="bio">
                                        <li v-if="form.has_phone" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                            <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">P:
                                                <!-- <i class="fas fa-phone-alt"></i> -->
                                            </label>
                                            <label :style="`color: ${form.icon_color};`" v-else>Phone:</label>
                                            <a style="text-decoration: none;" :style="`color: ${form.link_color};`" :href="`tel:${form.phone}`">{{ form.phone ? form.phone : placeholder.phone }}</a>
                                        </li>
                                        <li v-if="form.has_email" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                            <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">E:
                                                <!-- <i class="fas fa-envelope"></i> -->
                                            </label>
                                            <label :style="`color: ${form.icon_color};`" v-else>Email:</label>
                                            <a style="text-decoration: none;" :style="`color: ${form.link_color};`" :href="`mailto:${form.email}`"> {{ form.email ? form.email : placeholder.email }}</a>
                                        </li>
                                        <li v-if="form.has_website" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                            <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">W:
                                                <!-- <i class="fas fa-globe-americas"></i> -->
                                            </label>
                                            <label :style="`color: ${form.icon_color};`" v-else>Website:</label>
                                            <a style="text-decoration: none;" :style="`color: ${form.link_color};`" :href="form.website"> {{ form.website ? form.website : placeholder.website }}</a>
                                        </li>
                                        <li v-if="form.has_address && form.address" :class="{'px-0' : !form.is_abbreviate}" :style="`color: ${form.link_color};`">
                                            <label :style="`color: ${form.icon_color};`" v-if="form.is_abbreviate">A:
                                                <!-- <i class="fas fa-location-arrow"></i> -->
                                            </label>
                                            <label :style="`color: ${form.icon_color};`" v-else>Address:</label>
                                            {{ form.address ? form.address : placeholder.address }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mail_bar">
                                <div class="bar" :style="`height: ${form.linebar_height}px; width: ${form.linebar_width}%; background-color: ${form.linebar_color}; margin-top: ${form.linebar_mt}px; margin-bottom: ${form.linebar_mb}px;`"></div>
                                <div class="signatureFooter">
                                    <ul class="social-v1" :style="`margin-top:${form.social_mt}px; margin-bottom:${form.social_mb}px;`">
                                        <li v-if="form.is_facebook">
                                            <a :href="form.facebook_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                                <img v-if="form.is_facebook_icon" :src="require('@/assets/images/facebook-v2.svg')">
                                                <img v-else :src="form.facebook_icon ? form.facebook_icon : require('@/assets/images/facebook-v2.svg')">
                                            </a>
                                        </li>
                                        <li v-if="form.is_ig">
                                            <a :href="form.ig_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                                <img v-if="form.is_ig_icon" :src="require('@/assets/images/instagram-v2.svg')">
                                                <img v-else :src="form.ig_icon ? form.ig_icon : require('@/assets/images/instagram-v2.svg')">
                                            </a>
                                        </li>
                                        <li v-if="form.is_twitter">
                                            <a :href="form.twitter_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                                <img v-if="form.is_twitter_icon" :src="require('@/assets/images/twitter-v2.svg')" alt="">
                                                <img v-else :src="form.twitter_icon ? form.twitter_icon : require('@/assets/images/twitter-v2.svg')" alt="">
                                            </a>
                                        </li>
                                        <li v-if="form.is_linkedin">
                                            <a :href="form.linkedin_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                                <img v-if="form.is_linkedin_icon" :src="require('@/assets/images/linkedin-v2.svg')" alt="">
                                                <img v-else :src="form.linkedin_icon ? form.linkedin_icon : require('@/assets/images/linkedin-v2.svg')" alt="">
                                            </a>
                                        </li>
                                        <li v-if="form.is_yt">
                                            <a :href="form.yt_url" target="_blank" :styles="`color: ${form.social_icon_color}; border-color: ${form.social_icon_color};`">
                                                <img v-if="form.is_yt_icon" :src="require('@/assets/images/youtube-v2.svg')" alt="">
                                                <img v-else :src="form.yt_icon ? form.yt_icon : require('@/assets/images/youtube-v2.svg')" alt="">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="msgFooter text-center mt-2 mb-4">
                        <p>
                            <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><span :style="`color: ${form.icon_color};`">⚡</span> Powered by {{ whiteLabel.powered_by }}</a>
                                <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><span :style="`color: ${form.icon_color};`">⚡</span> Powered by {{ whiteLabel.powered_by }}</a>
                            </template>
                            <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><span :style="`color: ${form.icon_color};`">⚡</span> Powered by Thrive Coach</a>
                        </p>
                        <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                        <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                    </div>
                </div>
            </full-preview>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const FullPreview = defineAsyncComponent(() => import('@/components/FullPreview'))

    import * as yup from 'yup'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Add Signature',

        data(){
            const schema = yup.object({
                signature_name: yup.string().required().label('The signature name'),
                email: yup.string().email().label('The email'),
                facebook_url: yup.string().url().label('The url'),
                ig_url: yup.string().url().label('The url'),
                yt_url: yup.string().url().label('The url'),
                linkedin_url: yup.string().url().label('The url'),
                website: yup.string().url().label('The url'),
                linebar_width: yup.number().min(0).max(100).label('The width'),
            });

            return{
                schema,
                tab: 'brand',
                preview: true,
                fullPreview: false,
                device: 'desk',
                form: {
                    signature_name: '',
                    bar_color: '#2c3e50',
                    bar_text_color: '#ffffff',
                    border_radius: '5',
                    full_name: '',
                    credentials: 'PHD, CSCS',
                    company_name: '',
                    slogan: 'Coach without boundaries',
                    author_image: '',
                    phone: '',
                    email: '',
                    website: '',
                    address: '',
                    link_color: '#2c3e50',
                    is_abbreviate: 0,
                    is_facebook: 0,
                    facebook_url: '',
                    is_facebook_icon: 0,
                    facebook_icon: '',
                    is_ig: 0,
                    ig_url: '',
                    is_ig_icon: 0,
                    ig_icon: '',
                    is_yt: 0,
                    yt_url: '',
                    is_yt_icon: 0,
                    yt_icon: '',
                    is_linkedin: 0,
                    linkedin_url: '',
                    is_linkedin_icon: 0,
                    linkedin_icon: '',
                    use_default: 0,
                    apply_to_all: 0,
                    is_twitter: 0,
                    twitter_url: '',
                    is_twitter_icon: 0,
                    twitter_icon: '',
                    top_padding: 20,
                    bottom_padding: 20,
                    left_padding: 20,
                    right_padding: 20,
                    top_margin: 50,
                    bottom_margin: 30,
                    left_margin: 0,
                    right_margin: 0,
                    linebar_height: 2,
                    linebar_width: 100,
                    linebar_mt: 10,
                    linebar_mb: 10,
                    social_mt: 10,
                    social_mb: 10,
                    linebar_color: '#2c3e50',
                    social_icon_color: '#59c7f9',
                    social_icon_position: 'under',
                    icon_color: '#2c3e50',
                    has_phone: 1,
                    has_email: 1,
                    has_address: 1,
                    has_website: 1,
                },
                whiteLabel: {},
                sendSignatureLoader: false,
                showSignaturePreview: false,
                previewForm: {
                    content: '<p>Hi {{ user.full_name }}! </p><p>Welcome to the signature feature. You can create stunning email signatures that look amazing by editing your information on the left. You will see your signature appear in real time. When you are happy with your creation just hit the save button! </p>',
                    subject: 'Signature',
                    module: 'email',
                    email_address: this.user ? this.user.email : '',
                    is_email_logo: 1,
                    is_signature: 1,
                    signature_id: 0,
                    show_email_logo: 1,
                },
                placeholder: {
                    signature_name: 'ex: Studio Signature',
                    full_name: 'Joe Trainer',
                    credentials: 'PhD, CSCS',
                    company_name: 'Superfit Coaching, LLC',
                    slogan: 'Coach without boundaries ',
                    phone: '+1(999)999-999',
                    email: 'joe@superfitcoaching.com',
                    website: 'superfitcoaching.com',
                    address: '123 Super Street Supertown, CO 12345',
                },
            }
        },

        props: {
            modelValue: Boolean,
            editSignature: {
                type: Object,
                default: () => { return {} },
            },
            handleClose: Function,
        },

        watch: {
            editSignature () {
                const vm = this;

                vm.resetForm();
            },

            modelValue () {
                const vm = this;

                vm.resetForm();
                vm.showSignaturePreview = false;
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            FullPreview,
        },

        computed: mapState({
            signatureLoader: state => state.signatureModule.signatureLoader,
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
        }),

        mounted () {
            const vm = this;

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.previewForm.email_address = vm.user.email;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted () {
            const vm = this;

            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                storeSignature: 'signatureModule/storeSignature',
                getSignatureList: 'signatureModule/getSignatureList',
                deleteSignature: 'signatureModule/deleteSignature',
                sendTestEmail: 'commonModule/sendTestEmail',
            }),

            closeModal () {
                const vm = this;

                if (vm.handleClose) {
                    vm.handleClose();
                }

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    signature_name: vm.editSignature.signature_name ? vm.editSignature.signature_name : '',
                    bar_color: vm.editSignature.bar_color ? vm.editSignature.bar_color : '#2c3e50',
                    bar_text_color: vm.editSignature.bar_text_color ? vm.editSignature.bar_text_color : '#ffffff',
                    border_radius: vm.editSignature.border_radius ? vm.editSignature.border_radius : (vm.editSignature.border_radius == 0 ? 0 : 5),
                    full_name: vm.editSignature.full_name ? vm.editSignature.full_name : ( vm.editSignature.id ? '' : 'Joe Trainer'),
                    credentials: vm.editSignature.credentials ? vm.editSignature.credentials : (vm.editSignature.id ? '': 'PhD, CSCS'),
                    company_name: vm.editSignature.company_name ? vm.editSignature.company_name : (vm.editSignature.id ? '' : vm.user.businessname),
                    slogan: vm.editSignature.slogan ? vm.editSignature.slogan : (vm.editSignature.id ? '': 'Coach without boundaries'),
                    author_image: vm.editSignature.author_image ? vm.editSignature.author_image : '',
                    phone: vm.editSignature.phone ? vm.editSignature.phone : (vm.editSignature.id ? '' : vm.user.contactno),
                    email: vm.editSignature.email ? vm.editSignature.email : (vm.editSignature.id ? '' : 'joe@superfitcoaching.com'),
                    website: vm.editSignature.website ? vm.editSignature.website : (vm.editSignature.id ? '' : 'https://superfitcoaching.com'),
                    address: vm.editSignature.address ? vm.editSignature.address : (vm.editSignature.id ? '' : '123 Super Street Supertown, CO 12345'),
                    link_color: vm.editSignature.link_color ? vm.editSignature.link_color : '#2c3e50',
                    is_abbreviate: vm.editSignature.is_abbreviate ? vm.editSignature.is_abbreviate : 0,
                    is_facebook: vm.editSignature.is_facebook ? vm.editSignature.is_facebook : 0,
                    facebook_url: vm.editSignature.facebook_url ? vm.editSignature.facebook_url : '',
                    is_facebook_icon: vm.editSignature.is_facebook_icon ? vm.editSignature.is_facebook_icon : 0,
                    facebook_icon: vm.editSignature.facebook_icon ? vm.editSignature.facebook_icon : '',
                    is_ig: vm.editSignature.is_ig ? vm.editSignature.is_ig : 0,
                    ig_url: vm.editSignature.ig_url ? vm.editSignature.ig_url : '',
                    is_ig_icon: vm.editSignature.is_ig_icon  ? vm.editSignature.is_ig_icon : 0,
                    ig_icon: vm.editSignature.ig_icon ? vm.editSignature.ig_icon : '',
                    is_yt: vm.editSignature.is_yt ? vm.editSignature.is_yt : 0,
                    yt_url: vm.editSignature.yt_url ? vm.editSignature.yt_url : '',
                    is_yt_icon: vm.editSignature.is_yt_icon ? vm.editSignature.is_yt_icon : 0,
                    yt_icon: vm.editSignature.yt_icon ? vm.editSignature.yt_icon : '',
                    is_linkedin:vm.editSignature.is_linkedin ? vm.editSignature.is_linkedin :  0,
                    linkedin_url: vm.editSignature.linkedin_url ? vm.editSignature.linkedin_url : '',
                    is_linkedin_icon: vm.editSignature.is_linkedin_icon ? vm.editSignature.is_linkedin_icon : 0,
                    linkedin_icon: vm.editSignature.linkedin_icon ? vm.editSignature.linkedin_icon : '',
                    use_default: vm.editSignature.use_default ? vm.editSignature.use_default : 0,
                    apply_to_all: vm.editSignature.apply_to_all ? vm.editSignature.apply_to_all : 0,
                    top_padding: vm.editSignature.top_padding ? vm.editSignature.top_padding : 20,
                    bottom_padding: vm.editSignature.bottom_padding ? vm.editSignature.bottom_padding : 20,
                    left_padding: vm.editSignature.left_padding ? vm.editSignature.left_padding : 20,
                    right_padding: vm.editSignature.right_padding ? vm.editSignature.right_padding : 20,
                    top_margin: vm.editSignature.top_margin ? vm.editSignature.top_margin : 50,
                    bottom_margin: vm.editSignature.bottom_margin ? vm.editSignature.bottom_margin : 30,
                    left_margin: vm.editSignature.left_margin ? vm.editSignature.left_margin : 0,
                    right_margin: vm.editSignature.right_margin ? vm.editSignature.right_margin : 0,
                    linebar_height: vm.editSignature.linebar_height ? vm.editSignature.linebar_height : 2,
                    linebar_width: vm.editSignature.linebar_width ? vm.editSignature.linebar_width : 100,
                    linebar_color: vm.editSignature.linebar_color ? vm.editSignature.linebar_color : '#2c3e50',
                    social_icon_color: vm.editSignature.social_icon_color ? vm.editSignature.social_icon_color : '#59c7f9',
                    icon_color: vm.editSignature.icon_color ? vm.editSignature.icon_color : '#2c3e50',
                    social_icon_position: vm.editSignature.social_icon_position ? vm.editSignature.social_icon_position : 'under',
                    is_twitter: vm.editSignature.is_twitter ? vm.editSignature.is_twitter : 0,
                    twitter_url: vm.editSignature.twitter_url ? vm.editSignature.twitter_url : '',
                    is_twitter_icon: vm.editSignature.is_twitter_icon ? vm.editSignature.is_twitter_icon : 0,
                    twitter_icon: vm.editSignature.twitter_icon ? vm.editSignature.twitter_icon : '',
                    has_phone: vm.editSignature.has_phone ? vm.editSignature.has_phone : 0,
                    has_email: vm.editSignature.has_email ? vm.editSignature.has_email : 0,
                    has_address: vm.editSignature.has_address ? vm.editSignature.has_address : 0,
                    has_website: vm.editSignature.has_website ? vm.editSignature.has_website : 0,
                    linebar_mt: vm.editSignature.linebar_mt ? vm.editSignature.linebar_mt : 0,
                    linebar_mb: vm.editSignature.linebar_mb ? vm.editSignature.linebar_mb : 0,
                    social_mt: vm.editSignature.social_mt ? vm.editSignature.social_mt : 0,
                    social_mb: vm.editSignature.social_mb ? vm.editSignature.social_mb : 0,
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.id = vm.editSignature.id;
                params.setFieldError = setFieldError;

                vm.storeSignature(params).then((result) => {
                    if (result) {
                        const params = { page: 1, per_page: 5 }
                        vm.getSignatureList(params);
                        vm.closeModal();
                    }
                });
            },

            handleDeleteSignature () {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', 'You will not be able to recover this email signature.', 'Yes', false);

                Swal.fire(option).then((result) => {
                    if (result.isConfirmed) {
                        vm.deleteSignature(vm.editSignature.id).then((result) => {
                            const params = { page: 1, per_page: 5 };
                            vm.getSignatureList(vm.params);
                            vm.closeModal();
                        });
                    }
                });
            },

            resetToDefaultBarColor () {
                const vm = this;

                vm.form.bar_color = '#2c3e50';
                vm.form.bar_text_color  = '#ffffff';
            },

            resetToDefaultIconColor () {
                const vm = this;

                vm.form.icon_color = '#2c3e50';
                vm.form.link_color  = '#2c3e50';
            },

            resetToDefaultLineBarColor () {
                const vm = this;

                vm.form.linebar_color = '#2c3e50';
            },

            resetToDefaultSocialIconColor () {
                const vm = this;

                vm.form.social_icon_color = '#59c7f9';
            },

            handleSignatureSend () {
                const vm = this;
                vm.sendSignatureLoader = true;
                const previewParams = vm.previewForm;
                      previewParams.signature_id = vm.editSignature.id;
                const params = vm.form;
                      params.id = vm.editSignature.id;

                const form = vm.$refs['add-signature-form'];

                if (form) {
                    params.setFieldError = form.setFieldError;
                }

                vm.storeSignature(params).then((result) => {
                    if (result) {
                        vm.getSignatureList({ page: 1, per_page: 5 });
                        vm.sendTestEmail(previewParams).then((result) => {
                            if (result) {
                                vm.showSignaturePreview = false;
                            }

                            vm.sendSignatureLoader = false;
                        });
                    } else {
                        vm.sendSignatureLoader = false;
                    }
                });
            },

            toggleSectionBar () {
                const leftBar = document.querySelector('.global_setting');

                if (leftBar.classList.contains('show_m_sidebar')) {
                    leftBar.classList.remove('show_m_sidebar');
                } else {
                    leftBar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },
        },
    }
</script>


<style scoped>
    .mail_card {
        display: flex;
        flex-direction: column;
        /* border: 1px solid #eaeaea; */
        border-radius: 12px;
        /* padding: 10px; */
    }

    .mail_brand {
        background: #4C4C4E;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mail_brand h3 {
        font-size: 19px;
        line-height: 24px;
        color: #121525;
        font-weight: 500;
        align-items: flex-end;
    }

    .mail_brand h3 span {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
    }

    .mail_brand h6 {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 3px;
        display: block;
    }

    .social a {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-decoration: none;
        border: 1px solid #2f7eed;
        color: #2f7eed;
    }

    .social a img {
        max-width: 14px;
        max-height: 14px;
    }

    .mail_bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
    }

    .mail_bar .bar {
        border-radius: 3px;
        margin-bottom: 10px;
    }

    .mail_card .social {
        display: flex;
        align-items: center;
    }

    .mail_card .social li {
        padding-left: 7px;
    }

    .mail_card .social li img {
        height: 25px;
        width: 25px;
    }

    .mail_bio {
        padding: 25px 20px;
        display: flex;
        flex-direction: column;
    }

    .mail_bio img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        object-fit: cover;
    }

    .mail_bio .bio {
        margin-left: 15px;
    }

    .cell .mail_bio .bio {
        margin-left: 10px;
    }

    .mail_bio .bio li {
        font-size: 14px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 4px 0 4px 20px !important;
        position: relative;
    }

    .mail_bio .bio li label {
        font-weight: 500;
        color: #f2a31d;
        padding-right: 7px;
    }

    .mail_bio .bio li i {
        position: absolute;
        left: 0;
        top: 6px;
        color: #32373b;
    }

    .status_box .status .bar {
        height: 5px;
    }


    .text-center a  {
        text-decoration: none;
        color: #2c3e50;
    }


    .email-content p  {
        margin-bottom: 10px;
        text-align: left;
    }


    .email-content .action_wpr  {
        justify-content: center;
        margin: 30px 0;
    }


    .slogan  {
        width: 100%;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-bottom: 15px;
    }

    .bio_details {
        display: flex;
        align-items: center;
    }


    .social-v1  {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


    .social-v1 li  {
        margin: 0px 2px;
    }


    .social-v1 a  {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
    }

    /* .preview_content  {
        display: flex;
        flex-direction: column;
        width: 40%;
    } */

    .preview_content  {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 6;
        margin-right: -40%;
        display: flex;
        flex-direction: column;
    }

    .preview_content.show  {
        margin-right: 0;
    }

    .forms-setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    :deep(.upload_image label .tab_content) {
        height: auto;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 5px;
    }

    .preview_btn  {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .prev_content {
        padding: 30px 0;
        height: calc(100vh - 80px);
        overflow-y: scroll;
    }
    .prev_content::-webkit-scrollbar{
        display: none;
    }

    .preview_content .action_wpr {
        margin: auto -50px 0 -50px;
        padding: 20px 50px;
        border-top: 1px solid #eee;
    }

    .area_body .dashboard  {
        align-items: center;
        background: #fafafb;
    }

    .msg_preview  {
        padding: 30px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        max-width: 800px;
        width: auto;
        margin: 30px 20px 20px;
        position: relative;
    }

    .msg_preview:after  {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .msg_preview p {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .tab .msg_preview p {
        font-size: 14px;
        line-height: 20px;
    }

    .cell .msg_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview {
        margin: 12px;
        padding: 20px;
        border-radius: 8px;
        width: calc(100% - 24px);
    }

    .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }

    .cell .msgFooter p  {
        font-size: 11px;
        line-height: 17px;
    }

    .msg_preview .email-signature h3 {
        font-weight: 500;
    }

    .cell .mail_bio .bio li {
        padding: 2px 0 2px 10px!important;
    }

    .mail_card .mail_bar .social-v1 img, .mail_bar .signatureFooter .social-v1 img {
        border-radius: 50%;
    }
    .tabs_content .field_wpr.has_prefix{
        padding-left: 45px;
    }
    .tabs_content .field_wpr.has_prefix .prefix{
        width: 45px;
        font-size: 11px;
    }
    .tabs_content .field_wpr.has_prefix .prefix i{
        font-size: 16px;
    }

    @media(max-width: 1399px){
        .mail_brand{
            margin: -10px -40px;
            transform: scale(0.8);
        }
        .mail_card img{
            width: 50px;
            height: 50px;
        }
        .mail_bio{
            padding: 15px 10px;
        }
        .mail_bio .bio{
            margin: 0 ;
            transform: scale(0.8);
        }
        .mail_bio .slogan{
            font-size: 11px !important;
            margin: 0;
        }
        .mail_bar .bar{
            padding: 0;
            transform: scaleY(0.7);
        }
        .global_setting .preview_content p{
            font-size: 11px;
            line-height: 15px;
        }
        .tabs_content .setting_wpr .form_grp {
            row-gap: 30px;
        }
        .tabs_content .setting_wpr .group_item{
            min-width: 190px;
        }
    }
    @media(max-width: 991px){
        .global_setting .preview_content{
            width: 350px !important;
            margin-right: -350px !important;
        }
        .global_setting .preview_content.show{
            margin-right: 0 !important;
        }
    }
    @media(max-width: 499px){
        .tabs_content .setting_wpr .group_item .input_label{
            flex-wrap: wrap;
        }
        .tabs_content .setting_wpr .group_item .input_label small{
            margin-top: 10px;
        }
    }
</style>
